import { fetchError, fetchStart, fetchSuccess } from "../../../redux/actions";
import {
  setAuthUser,
  setForgetPassMailSent,
  setTrelloUserDetail,
  updateLoadUser,
  verifyTrelloUser
} from "../../../redux/actions/Auth";
import React from "react";
import axios from '../../config';

const BasicAuth = {
  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          const user = { name: "Admin", email: email, password: password };
          dispatch(fetchSuccess());
          localStorage.setItem("user", JSON.stringify(user));
          dispatch(setAuthUser(user));
        }, 300);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  verifyTrelloUser: ({ trelloUserData }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('user/verifyTrelloUser', {trelloUserData})
          .then(res => {
            // console.log("verifyTrelloUser result::", res.data.token);
            if (res.status === 200) {
              console.log("verifyTrelloUser fetchSuccess");
              dispatch(fetchSuccess());
              dispatch(verifyTrelloUser(res?.data));
              localStorage.setItem("trello_user_token", res?.data.token);
              dispatch(BasicAuth.getTrelloUserDetails(res?.data.token));
            } else {
              dispatch(fetchError(res.data.error));
            }
          })
          .catch(function (error) {
            dispatch(fetchError(error.m));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  getTrelloUserDetails: (token) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .get('user/userDetails',{
            headers:{
              'x-access-token':token
            }
          })
          .then(res => {
            // console.log("getTrelloUserDetails result::", res?.data?.data);
            if (res.status === 200) {
              console.log("getTrelloUserDetails fetchSuccess");
              dispatch(fetchSuccess());
              dispatch(setTrelloUserDetail(res?.data?.data));
              localStorage.setItem("userInfo", JSON.stringify(res?.data?.data));
            } else {
              dispatch(fetchError(res.data.error));
            }
          })
          .catch(function (error) {
            dispatch(fetchError(error.m));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        localStorage.removeItem("userInfo");
        window.location = './signin';
        dispatch(setAuthUser(null));
      }, 300);
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(() => {
        dispatch(fetchSuccess());
        dispatch(setAuthUser(JSON.parse(localStorage.getItem("user"))));
      }, 300);
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  }
};

export default BasicAuth;
