import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { styled, withStyles } from '@material-ui/styles';
import React from 'react';
import { AppTheme } from 'theme/customeTheme';
const options = [
    {
        label: "Mechanical : 2.75 hr."
    },
    {
        label: "Electrical : 2.75 hr."
    },
    {
        label: "Plumbing : 2.75 hr."
    },
];
const StyledMenu = styled((props) => (
    <Popover
        elevation={0}
        {...props}
    />
))(({ }) => ({
    '& .MuiPaper-root': {
        boxShadow:
            '0px 4px 4px rgba(0, 0, 0, 0.25);',
    },
}));
const CustomMenuItem = withStyles({
    root: {
        fontFamily: '"Poppins", sans-serif',
        color: "#4E4E4E",
        fontSize: 16,
        padding: "10px 15px",
        borderRadius: 16,
        margin: 16,
        letterSpacing: 0.2,
        minHeight: "auto",
        fontWeight: 500,
        border: "4px solid #96C0FF",
        fontStyle: "italic"
        // '&:hover': {
        //     backgroundColor: AppTheme.colors.primary,
        //     color: "#FFFFFF"
        //     // borderRadius: 5,
        // }
    }
})(MenuItem)
const HoursSpentPopover = ({ originalRow }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const showHours = (label) => {
        if (label.includes("Mechanical")) {
            return "Mechanical : " + originalRow?.Spent_Mech_Hours?.hours + " hr."

        } else
        if (label.includes("Electrical")) {
            return "Electrical : " + originalRow?.Spent_Elec_Hours?.hours + " hr."

        } else
        if (label.includes("Plumbing")) {
            return "Plumbing : " + originalRow?.Spent_Plum_Hours?.hours + " hr."

        } else return null
    };

    return (
        <div>
             <p className="text-truncate custom-label pointer"
                onClick={handleClick}
                style={{
                    border: '2px solid #006d8f'
                }}
            >{Number(originalRow?.Total_Spent_Hours).toFixed(2)}
            {/* {Number((originalRow?.Spent_Mech_Hours?.hours + originalRow?.Spent_Elec_Hours?.hours + originalRow?.Spent_Plum_Hours?.hours).toFixed(2))} */}
            </p>
            {/* <img src="/images/Clock.svg" alt="Clock" width="30px" onClick={handleClick} className="pointer" /> */}
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                <div className="hours-spent-popover">
                    <h3>Total Time Spent : {Number((originalRow?.Spent_Mech_Hours?.hours + originalRow?.Spent_Elec_Hours?.hours + originalRow?.Spent_Plum_Hours?.hours).toFixed(2))} hrs.</h3>
                    {options.map((item, i) => {
                        return (
                            <CustomMenuItem
                                style={{ borderColor: item.label.includes("Mechanical") && "#EAC76C" }}
                                // onClick={() => onClientStatusSubmit(item.label)}
                                key={i}
                            >{showHours(item.label)}</CustomMenuItem>
                        )
                    })}
                </div>
            </StyledMenu>
        </div>
    )
}
export default HoursSpentPopover;