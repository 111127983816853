import React, { useEffect, useState } from 'react';
import ProjectLabelType from './ProjectLabelType';

const LabelHighlighters = ({ labelNames }) => {
    return (
        <>
            {
                labelNames?.map((label, id) => {
                    return (
                        <ProjectLabelType key={id} labelType={label} />
                    )
                })
            }
        </>
    )
}

export default LabelHighlighters;