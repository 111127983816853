import { STORE_DASHBOARD_DATA, STORE_NOTIFICATION_DATA, CLEAR_NOTIFICATION_DATA } from "@jumbo/constants/ActionTypes";

export const setDashboardData = data => {
    return dispatch => {
        dispatch({
            type: STORE_DASHBOARD_DATA,
            payload: data
        });
    };
};
export const setNotificationData = data => {
    return dispatch => {
        dispatch({
            type: STORE_NOTIFICATION_DATA,
            payload: data
        });
    };
};
export const clearNotificationData = data => {
    return dispatch => {
        dispatch({
            type: CLEAR_NOTIFICATION_DATA,
        });
    };
};