import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Common from "./Common";
import Auth from "./Auth";
import Chat from './Chat';
import TableReducer from "./Table.reducer";
import DashboardReducer from "./Dashboard.reducer";

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    chat: Chat,
    table: TableReducer,
    dashboard: DashboardReducer,
  });
