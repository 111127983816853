import { AppTheme } from "theme/customeTheme";

export const sidebarLightTheme = {
  bgColor: "#ffffff80",
  textColor: AppTheme.colors.white,
  textDarkColor: "rgba(0, 0, 0, 0.87)",
  textActiveColor: AppTheme.colors.primary,
  navHoverBgColor: "rgb(229, 229, 229)",
  navActiveBgColor: AppTheme.colors.white,
  borderColor: "rgba(33, 33, 33, 0.08)",
  ...JSON.parse(localStorage.getItem("sidebar-colors"))
};

export const sidebarSemiDarkTheme = {
  bgColor: "#363636",
  textColor: "rgba(255, 255, 255, 0.3)",
  textDarkColor: "#fff",
  textActiveColor: "#fff",
  navHoverBgColor: "rgba(187, 134, 252, 0.08)",
  navActiveBgColor: "#3f51b5",
  borderColor: "rgba(255, 255, 255, 0.08)",
  ...JSON.parse(localStorage.getItem("sidebar-colors"))
};

export const sidebarDarkTheme = {
  bgColor: "#363636",
  textColor: "rgba(255, 255, 255, 0.3)",
  textDarkColor: "#fff",
  textActiveColor: "#fff",
  navHoverBgColor: "rgba(187, 134, 252, 0.08)",
  navActiveBgColor: "#3f51b5",
  borderColor: "rgba(255, 255, 255, 0.08)",
  ...JSON.parse(localStorage.getItem("sidebar-colors"))
};

const themeSidebarColor = {
  light: sidebarLightTheme,
  "semi-dark": sidebarSemiDarkTheme,
  dark: sidebarDarkTheme
};

export default themeSidebarColor;
