import CmtCard from "@coremat/CmtCard";
import { Box, Button, Card } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TrelloClient, { Trello } from "react-trello-client";
import { login, setAuthUser } from "redux/actions/Auth";
import { AppTheme } from "theme/customeTheme";
import { AuhMethods } from "../../../../../services/auth";
import { CurrentAuthMethod } from "../../../../constants/AppConstants";
import ContentLoader from "../../../ContentLoader";
import AuthWrapper from "../AuthWrapper";
import BasicAuth from '../../../../../services/auth/Basic';
const trelloImage = "/images/logos_trello.png";
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  },
  loginSideImg: {
    position: 'fixed',
    left: '0',
    top: '0'
  },
  loginSideImg2: {
    position: 'fixed',
    left: '120px',
    top: '0',
    width: '55px',
    height: 'fit-content',
  },
  loginSideCard: {
    position: 'fixed',
    left: '0',
    top: '200px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px 0px 0px',
    borderRadius: 15,
    maxWidth: '550px',
  },
  sideCardContent: {
    fontSize: '60px',
    textTransform: 'uppercase',
    paddingLeft: '20px',
    textAlign: 'right',
    lineHeight: 1
  },
  trelloLogin: {
    textAlign: 'right',
    fontSize: '80px',
    position: 'fixed',
    // marginLeft: '55px',
    right: '70px',
    top: '220px'
  },
}));
//variant = 'default', 'standard'
const SignIn = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default"
}) => {
  const [email, setEmail] = useState("demo@example.com");
  const [password, setPassword] = useState("demo#123");
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();
  const onSubmit = () => {
    dispatch(AuhMethods[method].onLogin({ email, password }));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <img src="/images/Rectangle 2.png" className={classes.loginSideImg} />
      <img src="/images/Rectangle 1.png" className={classes.loginSideImg2} />
      <CmtCard className={classes.loginSideCard}>
        <img src="/images/proLogo.png" className={classes.loginSideImg3} />
        <Typography className={classes.sideCardContent}>
          {/* <u>Priority Sheets</u> */}
          Priority Sheets
        </Typography>
      </CmtCard>
      {/* <Box className={classes.authContent}> */}
      <div className={classes.trelloLogin}>
        <TrelloClient
          // apiKey={"9b4f90e7956e4237dfe9002fcea50914"} // Get the API key from https://trello.com/app-key/
          apiKey={"54e96b0483c55d0ae8589024fe6978b8"} // Get the API key from https://trello.com/app-key///for server
          clientVersion={1} // number: {1}, {2}, {3}
          apiEndpoint="https://api.trello.com" // string: "https://api.trello.com"
          authEndpoint="https://trello.com" // string: "https://trello.com"
          intentEndpoint="https://trello.com" // string: "https://trello.com"
          authorizeName="Priority Sheets" // string: "React Trello Client"
          authorizeType="popup" // string: popup | redirect
          authorizePersist={true}
          authorizeInteractive={true}
          authorizeScopeRead={true} // boolean: {true} | {false}
          authorizeScopeWrite={false} // boolean: {true} | {false}
          authorizeScopeAccount={false} // boolean: {true} | {false}
          // authorizeExpiration={TOKEN_KEY_EXPI}
          authorizeOnSuccess={() => {
            setTimeout(() => {
              Trello.get('/members/me', (data) => {
                // console.log('succes', data);
                const trelloUserData = {
                  trelloToken: localStorage.getItem('trello_token'),
                  trello_id: data.id,
                  userName: data.username,
                  fullName: data.fullName,
                  innitials: data.initials,
                  // priorityToken: data.username,
                }
                dispatch(login(trelloUserData));
                dispatch(BasicAuth.verifyTrelloUser({trelloUserData}));
              }, (err) => {
                console.log('err', err);
              })
            }, 2000)
          }}
          authorizeOnError={() => console.log("Login error!")} // function: {() => console.log('Login error!')}
          autoAuthorize={false} // boolean: {true} | {false}
          authorizeButton={true}
          buttonStyle="metamorph" // string: "metamorph" | "flat"
          buttonColor="#0b0819" // string: "green" | "grayish-blue" | "light"
          buttonText="Login with Trello"
          // buttonText={<span><i className="fa fa-trello me-1"></i> {APP_CONFIG.AUTHORIZE_WITH_TRELLO} </span>} // string: "Login with Trello"
          buttonClasses="btn btn-white w-100"
          buttonCustomStyles={{
            backgroundColor: AppTheme.colors.primary,
            color: "#FFF",
            borderRadious: "10px",
            boxShadow: "none",
            fontSize: '40px'
            // border: "1px solid #fff"
          }}
        />
      </div>
      <ContentLoader />
      {/* </Box> */}
    </AuthWrapper >
  );
};

export default SignIn;
