import React from "react";

const FixedLogo = ({ }) => {
  return (
    <div className="fixed-logo">
        <img src="/images/pro-engineering.svg" alt="pro-engineering" className="pro-engineering"/>
        <img src="/images/priority-sheets.svg" alt="priority-sheets" className="priority-sheets"/>
    </div>
  );
};
export default FixedLogo;
