import axios from 'axios';
import { BASE_API_URL } from '../utils/globals';


const Axios = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  // withCredentials:true
});
Axios.interceptors.request.use((config) => {
  if (localStorage.getItem('trello_user_token')) {
    config.headers['x-access-token'] = localStorage.getItem('trello_user_token')
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
export default Axios