import makeStyles from "@material-ui/core/styles/makeStyles";
import {alpha} from "@material-ui/core/styles";
const bgImage = "/images/horizontal-header-bg-pattern.png";

const useStyles = makeStyles(theme => ({
  appHeaderDark: {
    backgroundImage: `URL(${bgImage})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    color: theme.palette.common.white,
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 1,
      width: "100%",
      height: "100%",
      backgroundColor: alpha(theme.palette.primary.main, 0.47)
    },
    "& > *": {
      position: "relative",
      zIndex: 3
    },
    "& .Cmt-btn": {
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        backgroundColor: alpha(theme.palette.common.white, 0.04),
        color: theme.palette.common.white
      }
    },
    "& .Cmt-divider": {
      backgroundColor: theme.palette.background.paper
    },
    "& .Cmt-search": {
      position: "relative",
      "& .MuiInputBase-input": {
        backgroundColor: alpha(theme.palette.common.dark, 0.38),
        color: theme.palette.common.white
      }
    },
    "& .Cmt-inputGroupAppend": {
      "& .MuiButton-root": {
        backgroundColor: alpha(theme.palette.common.dark, 0.38),
        color: theme.palette.common.white,
        borderLeftColor: alpha(theme.palette.common.white, 0.38)
      }
    },
    "& .Cmt-appIcon, & .Cmt-searchIcon": {
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        backgroundColor: alpha(theme.palette.common.white, 0.04),
        color: theme.palette.common.white
      }
    },
    "& .Cmt-lang-switch, & .Cmt-profile-pic": {
      "&:before": {
        backgroundColor: alpha(theme.palette.common.white, 0.38)
      }
    }
  }
}));

export default useStyles;
