import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { Box, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import CmtAvatar from '@coremat/CmtAvatar';
const useStyles = makeStyles(theme => ({
  tableContent: {
    fontSize: '20px',
    padding: '25px',
    color: '#000000'
  },
  cardContent: {
    paddingLeft: '15px'
  },
}));
const RecentPaymentsTable = () => {
  const classes = useStyles();
  return (
    <div className="Cmt-table-responsive">
      <Box display="flex" alignItems="center" className={classes.cardContent}>
        <CmtAvatar /><span className={classes.tableContent}>Monica Galler</span>
      </Box>
      <Box display="flex" alignItems="center" className={classes.cardContent}>
        <CmtAvatar /><span className={classes.tableContent}>Monica Galler</span>
      </Box>
      <Box display="flex" alignItems="center" className={classes.cardContent}>
        <CmtAvatar /><span className={classes.tableContent}>Monica Galler</span>
      </Box>
    </div>
  );
};

export default RecentPaymentsTable;
