import React, { Component }  from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: "16px"
    },
    closeButton: {
        position: 'absolute',
        right: "8px",
        top: "2px",
        color: "#9e9e9e"
    },
});
const DialogTitleCustom = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className="dialog-title">
            <h2 className="mb-0">{children}</h2>
            <IconButton aria-label="close" className={classes.closeButton} size="small" onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
    );
});
export default DialogTitleCustom;