import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  LOGIN,
  LOGOUT,
  TRELLO_USER_DETAILS,
  VERIFY_TRELLO_USER
} from "../../@jumbo/constants/ActionTypes";

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user
    });
  };
};

export const login = user => {
  return dispatch => {
    dispatch({
      type: LOGIN,
      payload: user
    });
  };
};

export const verifyTrelloUser = user => {
  return dispatch => {
    dispatch({
      type: VERIFY_TRELLO_USER,
      payload: user
    });
  };
};

export const setTrelloUserDetail = user => {
  return dispatch => {
    dispatch({
      type: TRELLO_USER_DETAILS,
      payload: user
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status
    });
  };
};

export const onLogout = user => {
  return dispatch => {
    dispatch({
      type: LOGOUT,
      // payload: user
    });
  };
};
